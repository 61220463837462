import axios from "axios";

export const getCovegare = async (params) => {
    try {
        const token = localStorage.getItem("token")
        const refresh_token = localStorage.getItem("refresh_token")
        const config = {
            headers: {
                Authorization: `${token}`,
                RefreshToken: refresh_token
            }
        }
        // console.log(config);
        const response = await axios.post(`/contracts`, params, config)

        // if (response.response.status)
        return response

    } catch (error) {
        if (error?.response?.status === 403) {
            logoutUser()
        }
        return []
    }
}

export const login = async (params) => {
    try {
        const response = await axios.post(`/authenticate`, params)
        return response

    } catch (error) {
        // console.log(error);
        return error
    }
}

function logoutUser() {
    localStorage.clear();
    window.location.href = window.location.origin
}

// const getOptions = (obj) => {
//     let str = ''
//     const paramsKeys = Object.keys(obj)
//     paramsKeys.map((e, i) => {
//         str += `${e.toString()}=${obj[e]}`
//         if (i >= 0 && i !== paramsKeys.length - 1) str += `&`;
//     })
//     return str
// }