import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCovegare } from '../../services/rest.services';

export const getCoveragesContent = createAsyncThunk('/coverages/content', async (params = {}) => {
    const response = await getCovegare(params)
    if (response.data.token) localStorage.setItem("token", response.data.token)
    response.data.params = params
    return response.data;
})

export const coveragesSlice = createSlice({
    name: 'coverage',
    initialState: {
        isEmpty: false,
        isLoading: false,
        coverages: [],
        isFilter: true
    },
    reducers: {

        emptyCoverage: (state, action) => {
            state.coverages = []
        },

        loadFilter: (state, action) => {
            state.isFilter = !state.isFilter
        },

        findAllWithParams: (state, action) => {
            let { newLeadObj } = action.payload
            state.coverages = [...state.coverages, newLeadObj]
        },

        addNewLead: (state, action) => {
            let { newLeadObj } = action.payload
            state.coverages = [...state.coverages, newLeadObj]
        },

        deleteLead: (state, action) => {
            let { index } = action.payload
            state.coverages.splice(index, 1)
        }
    },

    extraReducers: {
        [getCoveragesContent.pending]: state => {
            state.isLoading = true
        },
        [getCoveragesContent.fulfilled]: (state, action) => {
            // console.log(action.payload);
            if (action.payload.data.length === 0) {
                state.isLoading = false
                state.isEmpty = true
                state.coverages = []
                // state.isFilter = !state.isFilter
                // state.coverages = { ...action.payload, isEmpty: true }
            } else {
                state.coverages = action.payload
                state.isEmpty = false
                state.isLoading = false
            }

        },
        [getCoveragesContent.rejected]: state => {
            state.isLoading = false
        },
    }
})

export const { addNewLead, deleteLead, findAllWithParams, loadFilter, emptyCoverage } = coveragesSlice.actions

export default coveragesSlice.reducer