import axios from "axios"
// import jwt from "jsonwebtoken";
import { jwtDecode } from 'jwt-decode';

const checkAuth = () => {
  /*  Getting token value stored in localstorage, if token is not present we will open login page 
      for all internal dashboard routes  */


  const TOKEN = localStorage.getItem("token")

  const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"]

  const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r))
  // const sw = checkVigencyToken(TOKEN);
  // if (checkVigencyToken(TOKEN)) {
  if (!TOKEN && !isPublicPage) {
    window.location.href = '/login'
    return;
  } else {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`

    axios.interceptors.request.use(function (config) {
      // UPDATE: Add this code to show global loading indicator
      document.body.classList.add('loading-indicator');
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // UPDATE: Add this code to hide global loading indicator
      document.body.classList.remove('loading-indicator');
      return response;
    }, function (error) {
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    });
    return TOKEN
  }
  // } else {
  //   window.location.href = '/login'
  //   return;
  // }



}

export function checkVigencyToken(token) {
  try {
    token = localStorage.getItem("token")

    // Decodificar el token JWT
    const decodedToken = jwtDecode(token);

    // Obtener la fecha actual en segundos
    const currentTime = Date.now() / 1000;

    // Verificar si la fecha de expiración (exp) está presente en el token y si aún no ha expirado
    if (decodedToken.exp && currentTime > decodedToken.exp) {
      // El token ha expirado
      localStorage.setItem("token", null)
      return false;
    } else {
      // El token está vigente
      return true;
    }
  } catch (error) {
    localStorage.setItem("token", null)
    return false
  }

}

export default checkAuth