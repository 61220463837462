import axios from "axios"
import Keycloak from 'keycloak-js';
// import checkAuth from './app/auth';
import checkAuth from './auth';

const initializeApp = () => {

    let initOptions = {
        url: 'https://auth.lavitalicia.bo/',
        realm: 'salud',
        clientId: 'react-client',
        onLoad: 'login-required', // check-sso | login-required
        KeycloakResponseType: 'code',

        // silentCheckSsoRedirectUri: (window.location.origin + "/silent-check-sso.html")
    }

    let kc = new Keycloak(initOptions);

    kc.init({
        onLoad: initOptions.onLoad,
        KeycloakResponseType: 'code',
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html", checkLoginIframe: false,
        pkceMethod: 'S256'
    }).then((auth) => {
        if (!auth) {
            window.location.reload();
        } else {

            localStorage.setItem("token", kc.token)
            localStorage.setItem("refresh_token", kc.refreshToken)
            // const tokenPased = kc.tokenParsed

            // localStorage.setItem("user", tokenPased.preferred_username);
            // localStorage.setItem("user", tokenPased.preferred_username);
            checkAuth()
            kc.onTokenExpired = () => {

                kc.logout({ redirectUri: process.env.BACKEND_AUTH_BASE_URL })
            }
        }
    }, () => {
        kc.logout({ redirectUri: process.env.BACKEND_AUTH_BASE_URL })
    })
    // Setting base URL for all API request via axios
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "http://127.0.0.1:3000/api/salud"


    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code


    } else {
        // Prod build code





        // init analytics here
    }
    return kc;
}

export default initializeApp