import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import { checkVigencyToken } from './app/auth';
import initializeApp from './app/init';
import { login } from './services/rest.services';
// import Keycloak from 'keycloak-js';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'))
const Register = lazy(() => import('./pages/Register'))
const Documentation = lazy(() => import('./pages/Documentation'))


// Initializing different libraries
const kc = initializeApp()

// let initOptions = {
//   url: 'https://auth.lavitalicia.bo/',
//   realm: 'salud',
//   clientId: 'react-client',
//   onLoad: 'login-required', // check-sso | login-required
//   KeycloakResponseType: 'code',

//   // silentCheckSsoRedirectUri: (window.location.origin + "/silent-check-sso.html")
// }

// let kc = new Keycloak(initOptions);

// kc.init({
//   onLoad: initOptions.onLoad,
//   KeycloakResponseType: 'code',
//   silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html", checkLoginIframe: false,
//   pkceMethod: 'S256'
// }).then((auth) => {
//   if (!auth) {
//     window.location.reload();
//   } else {
//     localStorage.setItem("token", kc.token)
//     localStorage.setItem("refresh_token", kc.refreshToken)
//     checkAuth()
//     kc.onTokenExpired = () => {
//       kc.logout({ redirectUri: 'https://gm.lavitalicia.bo/' })
//     }
//   }
// }, () => {
// });

// Check for login and initialize axios
// const token = checkAuth()

function Location() {
  const location = useLocation();
  const navigate = useNavigate();

  const exp = async () => {
    return kc.isTokenExpired(5);
  }

  const getParsedToken = () => {
    return kc.tokenParsed;
  }

  useEffect(() => {

    // const s = kc.login();

    // login(kc.tokenParsed);
    // let s = checkVigencyToken();
    // if (!checkVigencyToken()) {
    //   kc.logout()
    // }
    // 


    // const exp = kc.isTokenExpired();
    // if (kc.isTokenExpired()) {
    // kc.logout({ redirectUri: 'https://gm.lavitalicia.bo/' })
    // }
    // if (location.pathname !== '/login' && location.pathname !== '/reset-password') {
    //   const token = checkAuth();


    // if (token === 'null' && location.pathname !== '/login') {

    // <Navigate to={"/login"} />
    // navigate('/login');
    //       // // history.push('/login');

    // window.location.href = '/login'
    //   }
    // }

    //     // Realizar acciones según el cambio de URL


    // }, [location.pathname, navigate]);
  }, []);
  return null;
}

function App() {

  useEffect(() => {

    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])


  return (
    <>
      <Router>
        <Location />
        <Routes>
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
          {/* <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} /> */}

          {/* Place new routes over this */}
          {/* <Route path="/app/*" element={<Layout />} /> */}
          {/* <Route path="/app/*" element={token === 'null' ? <Navigate to={"/login"} replace /> : <Layout />} /> */}
          {/* <Route path="/*" element={!kc.authenticated ? kc.logout({ redirectUri: process.env.BACKEND_AUTH_BASE_URL }) : <Layout kc={kc} />} /> */}
          <Route path="/*" element={<Layout kc={kc} />} />

          {/* <Route path="*" element={<Navigate to={token ? "/app/welcome" : "/login"} replace />} /> */}

        </Routes>
      </Router>
      {/* <button className="btn px-6 btn-sm normal-case mr-1" onClick={() => { kc.logout({ redirectUri: 'http://localhost:3000/' }) }}>LogOut</button> */}
    </>
  )
}

export default App
