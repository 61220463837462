import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import coverageSlice from '../features/coverage/coverageSlice'
import clientsSlice from '../features/clients/clientSlice'

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  coverage: coverageSlice,
  client: clientsSlice
}

export default configureStore({
  reducer: combinedReducer
})