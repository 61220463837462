import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './app/store'
import { Provider } from 'react-redux'
import SuspenseContent from './containers/SuspenseContent';
// import keycloakInst from './components/Keycloak';
// import { ReactKeycloakProvider } from '@react-keycloak/web';
// const initOptions = { onLoad: 'login-required' };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <ReactKeycloakProvider authClient={keycloakInst} initOptions={initOptions}>
  <Suspense fallback={<SuspenseContent />}>
    <Provider store={store}>


      <App />
    </Provider>
  </Suspense>
  // </ReactKeycloakProvider>,
  // document.getElementById('root')
  // <React.StrictMode>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
