import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const getClientsContent = createAsyncThunk('/leads/content', async (params = "") => {
    const response = await axios.get(`/rest/clients?${params}`)
    response.data.params = params
    return response.data;
})

export const leadsSlice = createSlice({
    name: 'clients',
    initialState: {
        isLoading: false,
        clients: []
    },
    reducers: {

        addNewLead: (state, action) => {
            let { newLeadObj } = action.payload
            state.leads = [...state.leads, newLeadObj]
        },

        deleteLead: (state, action) => {
            let { index } = action.payload
            state.leads.splice(index, 1)
        }
    },

    extraReducers: {
        [getClientsContent.pending]: state => {
            state.isLoading = true
        },
        [getClientsContent.fulfilled]: (state, action) => {
            state.leads = action.payload
            state.isLoading = false
        },
        [getClientsContent.rejected]: state => {
            state.isLoading = false
        },
    }
})

export const { addNewLead, deleteLead, findAllWithParams } = leadsSlice.actions

export default leadsSlice.reducer